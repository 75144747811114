<template>
	<div>
		<div class="input-field relative" :class="[error ? 'error' : '']">
			<input
				:id="fieldId"
				ref="input"
				:name="name"
				class="ring-none block w-full appearance-none rounded border px-3 pb-1 pt-5 shadow-none outline-none ring-transparent focus:outline-none"
				:class="[
					disabled ? disabledBackgroundColor : backgroundColor,
					error
						? 'border-red-400 border-opacity-80'
						: 'border-gray-200 focus:border-blue-400',
				]"
				placeholder=" "
				:value="modelValue"
				:type="type ?? 'text'"
				:disabled="isDisabled"
				:autocomplete="fieldAutoCompleteValue"
				:maxlength="maxlength"
				@input="onChange"
			/>
			<label
				:for="fieldId"
				class="pointer-events-none absolute top-1/2 origin-top-left -translate-y-1/2 px-3 duration-300"
			>
				{{
					typeof error === 'string' && error && showErrorAsLabel
						? error
						: label
				}}
			</label>
			<span
				v-if="isLoading"
				class="multiselect-spinner absolute right-4 top-4"
			/>
		</div>
		<div
			v-if="error && showError"
			class="mt-1.5 flex flex-row justify-start space-x-1"
		>
			<div class="text-sm text-red-400 opacity-70">
				{{ error }}
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
	modelValue: String,
	error: {
		type: [String, Boolean],
		default: false,
		required: false,
	},
	id: {
		type: String,
		default: null,
		required: false,
	},
	name: {
		type: String,
		default: null,
		required: true,
	},
	type: {
		type: String,
		default: 'text',
		required: false,
	},
	label: {
		type: String,
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
		required: false,
	},
	isLoading: {
		type: Boolean,
		default: false,
		required: false,
	},
	backgroundColor: {
		type: String,
		default: 'bg-white',
		required: false,
	},
	disabledBackgroundColor: {
		type: String,
		default: 'bg-gray-200',
		required: false,
	},
	showErrorAsLabel: {
		type: Boolean,
		default: false,
		required: false,
	},
	showError: {
		type: Boolean,
		default: true,
		required: false,
	},
	isDisabled: {
		type: Boolean,
		default: false,
		required: false,
	},
	autoCompleteName: {
		type: String,
		default: null,
		required: false,
	},
	maxlength: {
		type: Number,
		default: 524288,
		required: false,
	},
})

const emit = defineEmits(['update:modelValue', 'changeHandler'])

const fieldId = computed(() => props.id ?? props.name)

const fieldAutoCompleteValue = computed(() => {
	if (props.autoCompleteName) {
		return props.autoCompleteName
	}
	return props.name
})

const onChange = (event) => {
	emit('update:modelValue', event.target.value)
	emit('changeHandler', event.target.value)
}

const input = ref(null)
</script>
